import gql from 'graphql-tag'

export const PLANS_QUERY = gql`
  query PlansQuery {
    plans: plans {
      id
      name
      duration
      minimumDuration
      status
    }
  }
`