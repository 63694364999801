<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-3">
      <v-card-title class="justify-center">
        <span class="headline">Plano</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="plan.name"
              :readonly="show"
              label="Nome"
              outlined
              name="name"
              type="text"
              dense
              required
              hide-details
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model.number="plan.duration"
              :readonly="show"
              label="Duração"
              outlined
              name="duration"
              type="number"
              dense
              required
              hide-details
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model.number="plan.minimumDuration"
              :readonly="show"
              label="Limite Minímo"
              outlined
              name="minimumDuration"
              type="number"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            color="primary"
            :outlined="!show"
            class="mx-2"
            @click="$emit('close')"
            >{{ show ? "Fechar" : "Cancelar" }}</v-btn
          >
          <v-btn
            v-if="!show"
            :disabled="isLoading"
            color="primary"
            @click="plan.id ? update() : save()"
            >Salvar</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import {
  CREATE_PLAN_MUTATION,
  UPDATE_PLAN_MUTATION,
} from "./../graphql/Mutation.gql";
import { formatError } from "@/utils";
import { mapActions } from "vuex";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
export default {
  name: "AddPlanDialog",
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  props: {
    dialog: Boolean,
    plan: Object,
    show: Boolean,
  },
  data: () => ({
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: undefined,
  }),
  computed: {
    requiredRules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },
  methods: {
    ...mapActions({
      setPlan: "plan/setPlan",
      updatePlanState: "plan/updatePlan",
    }),
    async save() {
      this.isLoading = true;
      try {
        const { name, duration, minimumDuration } = this.plan;
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_PLAN_MUTATION,
          variables: {
            planInput: {
              name,
              duration,
              minimumDuration,
            },
          },
        });
        this.setPlan(data.createPlan);
        this.success = "Plano criado com sucesso!";
        this.showSuccess = true;
        this.$emit("close");
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async update() {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_PLAN_MUTATION,
          variables: {
            id: this.plan.id,
            planInput: this.plan,
          },
        });
        this.updatePlanState(data.updatePlan);
        this.success = "Plano actualizado com sucesso!";
        this.showSuccess = true;
        this.$emit("close");
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>