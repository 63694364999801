import gql from "graphql-tag";

export const DELETE_PLAN_MUTATION = gql`
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id) {
      id
    }
  }
`;

export const CREATE_PLAN_MUTATION = gql`
  mutation CreatePlan($planInput: PlanInput!) {
    createPlan(planInput: $planInput) {
      id
      name
      duration
      minimumDuration
      status
    }
  }
`;
export const UPDATE_PLAN_MUTATION = gql`
  mutation UpdatePlan($planInput: PlanInput) {
    updatePlan(planInput: $planInput) {
      id
      name
      duration
      minimumDuration
      status
    }
  }
`;
