<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle">
          <strong>Plan</strong>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
            rounded
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          rounded
          text
          outlined
          @click.prevent="dialog = !dialog"
        >
          <v-icon
            left
            color="#F7A400"
          >mdi-plus-circle-outline</v-icon>Novo Plano
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="getPlans"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="blue"
              x-small
              fab
              class="ma-1"
              dark
              @click="showModule(item)"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              x-small
              fab
              class="ma-1"
              dark
              @click="edit(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              x-small
              fab
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-plan-dialog 
      :dialog="dialog" 
      :plan="plan" 
      :show="show" 
      @close="closeAddDialog()"
    />
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Modulo?" 
      @yes="deletePlan" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-container>
</template>

<script>
import { PLANS_QUERY } from './../graphql/Query.gql'
import { DELETE_PLAN_MUTATION } from './../graphql/Mutation.gql'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import Plan from '@/models/Plan'
import AddPlanDialog from './../components/AddPlanDialog'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'Plan',
  mixins: [
    dialogMixins
  ],
  components: {
    AddPlanDialog,
    ConfirmDialog, 
    ErrorDialog, 
    ProgressDialog, 
    SuccessDialog,
  },
  data: () => ({
    alert: true,
    plans: [],
    plan: new Plan(),
  }),
  apollo: {
    plans: {
      query: PLANS_QUERY
    }
  },
  watch: {
    plans: function (val) {
      this.setPlans(val)
    }
  },
  computed: {
    ...mapGetters({
      getPlans: 'plan/getPlans'
    }),
    headers () {
      return [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Duração",
          align: "end",
          sortable: false,
          value: "duration"
        },
        {
          text: "Limite minímo",
          align: "end",
          sortable: false,
          value: "minimumDuration"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        { text: "Acção", align: "center", value: "actions" }
      ];
    }
  },
  methods: {
    ...mapActions({
      removeModule: 'plan/removePlan',
      setPlans: 'plan/setPlans'
    }),
    closeAddDialog () {
      this.plan = new Plan()
      this.show = false
      this.dialog = false
    },
    async deletePlan (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_PLAN_MUTATION,
          variables: {
            id
          }
        })
        this.removeModule(id)
        this.success = 'Plano removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    edit (plan) {
      this.plan = plan
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    showPlan (plan) {
      this.plan = plan
      this.show = true
      this.dialog = true
    }
  }
}
</script>